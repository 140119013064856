import React, { useState, useEffect } from "react";
import "./styles/leaveapplication.css";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import logger from './logs/logger';
import { validateLeaveApplication, validateFile } from './Utils/Validations';
import {ApiService} from "../src/services/apiservices/apiservice";

const LeaveApplication = () => {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reason, setReason] = useState("");
  const [casualReasons, setCasualReasons] = useState([]);
  const [sickReasons, setSickReasons] = useState([]);
  const [annualReasons, setAnnualReasons] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [othersReason, setOthersReason] = useState("");
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [halfDayTime, setHalfDayTime] = useState("");
  const [isHalfDay, setIsHalfDay] = useState(false);
  const navigate = useNavigate();
  const [applyResponse, setApplyResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const[medicalcertificate,setmedicalcertificate]=useState(null);
  const [fileError, setFileError] = useState('');
  const [accessToken, setAccessToken] = useState(
    UserHeaders.ACCESSTOKEN.value
  );
  const apiService = new ApiService(navigate);
 const [validationErrors, setValidationErrors] = useState({
    leaveType: "",
    fromDate: "",
    toDate: "",
    reason: "",
    othersReason: "",
    halfDayTime: ""
 });

  const country = UserHeaders.COUNTRY.value;
  const userId = UserHeaders.USERID.value;

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const apiUrl = `metadata/leavetypes?country=${country}&userId=${userId}`;
        // api call for leave types dropdown 
        const response = await apiService.sendRequest(apiUrl, {});

        if (response.success) {
          const data = response.data;
          const optionsArray = Object.keys(data).map((key) => {
            return {
              name: data[key].name,
              description: data[key].description,
              enumValue: key,
              id: data[key].id,
            };
          });

          setLeaveTypes(optionsArray);
        } else {
          logger.error("Failed to fetch dropdown options", response.error);
        }
      } catch (error) {
        logger.error("An error occurred while fetching dropdown options:", error);
      }
  };

  const fetchReasons = async () => {
     try {
        // API call for reason
        const response = await apiService.sendRequest('metadata/reasons', {});
    
        if (response.success) {
          const data = response.data;
          const reasonMap = {
            'CASUAL': [],
            'SICK': [],
            'ANNUAL': [],
          };
    
          // Iterate over the API response data
          Object.keys(data).forEach((key) => {
            const reason = {
              name: data[key].name,
              description: data[key].description,
              enumValue: key,
              id: data[key].id,
            };

            const leaveTypeName = data[key].leaveType.name.toUpperCase();

             // Check if the leaveType corresponds to one of the defined leave types
             if (reasonMap[leaveTypeName]) {
               reasonMap[leaveTypeName].push(reason);
             }
          });
    
          // Set the state for each leave type
          setCasualReasons(reasonMap.CASUAL);
          setSickReasons(reasonMap.SICK);
          setAnnualReasons(reasonMap.ANNUAL);
        } else {
          logger.error("Failed to fetch reasons:", response.error);
        }
       } catch (error) {
        logger.error("An error occurred while fetching reasons:", error);
      }
     };
    
    fetchDropdownOptions();
    fetchReasons();
  }, []);

  useEffect(() => {
    if (fromDate && toDate  && !isHalfDay) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      const daysDifference = Math.ceil((to - from + 1) / (1000 * 60 * 60 * 24));
      setNumberOfDays(daysDifference);
    } else if (isHalfDay && halfDayTime) {
      setNumberOfDays(0.5);
    } else {
      setNumberOfDays("");
    }
  }, [fromDate, toDate, isHalfDay, halfDayTime]);

  useEffect(() => {
    if (leaveType === 'CASUAL') {
      setReasons(casualReasons);
    } else if (leaveType === 'SICK' || leaveType === 'SICK_LEAVE') {
      setReasons(sickReasons);
    } else if (leaveType === 'ANNUAL' || leaveType === 'EARNED_LEAVE') {
      setReasons(annualReasons);
    } else {
      setReasons([]);
    }
    setReason("");
    if (leaveType !== 'SICK' && leaveType !== 'CASUAL') {
      setIsHalfDay(false);
      setHalfDayTime("");
    }
  }, [leaveType, casualReasons, sickReasons, annualReasons]);

  const validateForm = () => {
    const errors = {
      leaveType: leaveType ? "" : "Leave Type is required",
      fromDate: fromDate ? "" : "From Date is required",
      toDate: (!isHalfDay && toDate) || (isHalfDay && !toDate) ? "" : "To Date is required when it's not a half-day",
      reason: reason ? "" : "Reason is required",
      halfDayTime: isHalfDay && !halfDayTime ? "Half-Day Time is required" : "",
    };

    if (reason === "SICK_OTHERS" || reason === "ANNUAL_OTHERS" || reason === "CASUAL_OTHERS") {
       errors.othersReason = othersReason ? "" : "Other Reason is required";
    }

    if ((leaveType === 'CASUAL' || leaveType === 'ANNUAL') && fromDate) {
      const fromDateObj = new Date(fromDate);
      const today = new Date();
      fromDateObj.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      if (fromDateObj < today) {
        errors.fromDate = `Cannot select a past date for ${leaveType.toLowerCase()} leave`;
      }
    }
    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      if (fromDateObj > toDateObj) {
        errors.toDate = "From Date should be before the To Date";
      }
    }

    if (isHalfDay && !halfDayTime) {
      errors.halfDayTime = "Half-Day Time is required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      logger.info("File selected:", file.name, "Type:", file.type, "Size:", file.size);
    }
    const validatedFile = validateFile(file, setFileError);
    if (validatedFile) {
      setmedicalcertificate(validatedFile);
    } else {
      logger.info("File validation failed. Error:", fileError);
    }
  };

  const handleSubmit = async (e) => {
    await handleExpiredAccessToken(navigate, setAccessToken);
    if (isSubmitting) return;

    setIsSubmitting(true);

    e.preventDefault();
    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

   try {
     const formData = new FormData();
     const leaveRequest = {
       leaveType: { leave: leaveType },
       fromDate: fromDate,
       toDate: isHalfDay ? fromDate : toDate,
       no_of_days: numberOfDays,
       reason: reason,
       otherReason: othersReason,
       halfDayTime: isHalfDay ? halfDayTime : null
     };
    formData.append('leaveRequest', new Blob([JSON.stringify(leaveRequest)], { type: 'application/json' }));
    if (medicalcertificate) {
      logger.info("Appending medical certificate to form data:", medicalcertificate);
      formData.append('medicalcertificate', medicalcertificate);
      logger.info("Uploading Medical Certificate:", medicalcertificate);
    }
    for (const [key, value] of formData.entries()) {
      logger.info(key, value instanceof File ? value.name : value);
    }
    const response = await fetch(`${API_BASE_URL}/apply-leave/${UserHeaders.USERID.value}`, {
      method: "POST",
      body: formData,
    });

     if (response.ok) {
       logger.info("Leave application submitted successfully!");
       const successResponse = await response.json();
       logger.info("apply status:", successResponse.description);
       setApplyResponse({ description: successResponse.description, code: successResponse.code });
       setLeaveType("");
       setFromDate("");
       setToDate("");
       setReason("");
       setNumberOfDays(0);
       setHalfDayTime("");
       setIsHalfDay(false);
       setTimeout(() => navigate("/home/leaverequest"), 3000);
     } else {
        const errorResponse = await response.json();
        setApplyResponse({ description: errorResponse.message });
        logger.error("Error submitting leave application:",response.status,errorResponse);
     }
   } catch (error) {
      logger.error("An error occurred during submission:", error.message);
   } finally {
      setIsSubmitting(false); // Reset submitting state
   }
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const handleReasonChange = (e) => {
    const selectedReason = e.target.value;
    setReason(selectedReason);
    if (selectedReason !== "ANNUAL_OTHERS" && selectedReason !== "SICK_OTHERS" && selectedReason !== "CASUAL_OTHERS") {
      setOthersReason("");
    }
  };

  const handleHalfDayToggle = () => {
    setIsHalfDay(!isHalfDay);
  };

  const handleHalfDayTimeChange = (e) => {
    setHalfDayTime(e.target.value);
    if (e.target.value) {
      setNumberOfDays(0.5);
    } else {
      setNumberOfDays("");
    }
  };

  return (
    <div className="leave-application-container">
      <div className="leave-form-container">
        <form className="leave-application-form" method="POST">
          <div className="form-group full-width">
            <label>
              Leave Type:
              <select value={leaveType} onChange={(e) => setLeaveType(e.target.value)} className={leaveType ? "" : "input-placeholder"} >
                <option value="" disabled >
                  Select Leave Type
                </option>
                {leaveTypes && leaveTypes.length > 0 ? (
                  leaveTypes.map((option, index) => (
                    <option key={index} value={option.enumValue}>
                      {option.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No Leave Types Available
                  </option>
                )}
              </select>
            </label>
            <div> <p className="leave-error-message">{validationErrors.leaveType}</p> </div>
          </div>

          {(leaveType === 'CASUAL' || leaveType === 'SICK'|| leaveType === 'SICK_LEAVE') && (
            <div className="form-group full-width">
              <label>
                <input
                  type="checkbox"
                  checked={isHalfDay}
                  onChange={handleHalfDayToggle}
                />
                Half Day
              </label>
            </div>
          )}
          {leaveType && (
            <>
              <div className="form-row">
                <div className="form-group half-width">
                  <label> From Date
                    <input
                      type="date"
                      id="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      className={fromDate ? "" : "input-placeholder"}
                      min={(leaveType === 'CASUAL'|| leaveType === 'ANNUAL') ? new Date().toISOString().split('T')[0] : ''}
                    />
                  </label>
                  <div> {validationErrors.fromDate && ( <p className="leave-error-message">{validationErrors.fromDate}</p> )} </div>
                </div>
                {isHalfDay ? (
                  <>
                    <div className="form-group half-width">
                      <label> Timing
                        <select
                          id="halfDayTime"
                          value={halfDayTime}
                          onChange={handleHalfDayTimeChange}
                          className={halfDayTime ? "" : "input-placeholder"}
                        >
                          <option value="">Select Time</option>
                          <option value="9:30am-1:30pm">9:30 AM - 1:30 PM</option>
                          <option value="2:30pm-6:30pm">2:30 PM - 6:30 PM</option>
                        </select>
                      </label>
                      <div> {validationErrors.halfDayTime && ( <p className="leave-error-message">{validationErrors.halfDayTime}</p> )} </div>
                    </div>
                  </>
                ) : (
                  <div className="form-group half-width">
                    <label> To Date
                      <input
                        type="date"
                        id="toDate"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className={toDate ? "" : "input-placeholder"}
                        min={(leaveType === 'CASUAL'|| leaveType === 'ANNUAL') ? new Date().toISOString().split('T')[0] : ''}
                      />
                    </label>
                    <div> {validationErrors.toDate && ( <p className="leave-error-message">{validationErrors.toDate}</p> )} </div>
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="form-group full-width">
                  <label>
                    Number of Days:
                    <input
                      type="number"
                      value={numberOfDays}
                      readOnly
                      className={numberOfDays ? "" : "input-placeholder"}
                    />
                  </label>
                </div>
              </div>
              <div className="form-group full-width">
                <label>
                  Reason for Leave:
                  <select value={reason} onChange={handleReasonChange} className={reason ? "" : "input-placeholder"}>
                    <option value="" disabled>Select Reason</option>
                    {reasons.length > 0 ? (
                      reasons.map((option, index) => (
                        <option key={index} value={option.enumValue}>{option.name}</option>
                      ))
                    ) : (
                      <option value="" disabled>No Reasons Available</option>
                    )}
                  </select>
                </label>
                <div> <p className="leave-error-message">{validationErrors.reason}</p> </div>
              </div>
              {(reason === "SICK_OTHERS" || reason === "ANNUAL_OTHERS" || reason === "CASUAL_OTHERS") && (
                <div className="form-group full-width">
                  <label>
                    Other Reasons:
                    <textarea value={othersReason} onChange={(e) => setOthersReason(e.target.value)} className={othersReason ? "" : "input-placeholder"}/>
                  </label>
                  <div> <p className="leave-error-message">{validationErrors.othersReason}</p> </div>
                </div>
              )}
              &nbsp;
            </>
          )}
          {(leaveType === "SICK" || leaveType === "SICK_LEAVE") && (
            <>
              {(UserHeaders.COUNTRY.value === "IN_BGL" || UserHeaders.COUNTRY.value === "IN_CBE"? numberOfDays >= 3 : numberOfDays > 2) && (
                <div className="form-row">
                  <div className="form-group full-width">
                    <label htmlFor="file-upload">
                      Upload Medical Certificate (if applicable):
                      <input id="file" type="file" accept=".jpg,.jpeg,.png,.pdf" onChange={handleFileChange}/>
                    </label>
                    <div>{fileError && <p className="error-message">{fileError}</p>}</div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="form-row">
            <button type="button" onClick={handleSubmit} disabled={isSubmitting} className="submit-button">
              Submit
            </button>
            <button type="button" onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>
          {applyResponse && ( <div className={`apply-response ${ applyResponse.code === 201 ? "success" : "error"}`}> <p>{applyResponse.description}</p> </div>)}
        </form>
      </div>
    </div>
  );
 };

export default LeaveApplication;
