import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import { getAuthHeaders } from './authUtils';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { handleExpiredAccessToken } from './authUtils';
import logger from "./logs/logger";
import './styles/HolidayProgress.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const HolidayDetails = () => {
  const [holidays, setHolidays] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [countries, setCountries] = useState([]);
  const [fetchedCountries, setFetchedCountries] = useState([]);
  const [itemsPerPage] = useState(8);
  const navigate = useNavigate();
  const userRole = parseInt(UserHeaders.USERROLE.value, 10);
  const userCountry = UserHeaders.COUNTRY.value;
  const userId = UserHeaders.USERID.value;
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);

  useEffect(() => {
   fetchCountries();
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
  try {
    await handleExpiredAccessToken(navigate, setAccessToken);
    const response = await fetch(`${API_BASE_URL}/holidays`, {
      method: 'POST',
      headers: {
        ...getAuthHeaders(accessToken),
        'X-Country': userCountry,
         'X-UserRole': userRole,
         'X-UserId' : userId
      }
    });
      if (response.ok) {
        const data = await response.json();
        if (data.code === 200 && Array.isArray(data.message)) {
          setHolidays(data.message);
        } else {
          logger.error("Unexpected response structure:", data);
          setHolidays([]); // Fallback to an empty array
        }
      } else {
        logger.error("Failed to fetch holidays");
      }
    } catch (error) {
      logger.error("Error fetching holidays:", error);
    }
  };
  const fetchCountries = async () => {
      try {
       const response = await fetch(`${API_BASE_URL}/metadata/countries`, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
         },
       });
        if (response.ok) {
          const data = await response.json();
          const countryNames = Object.keys(data).map((key) => ({
            enumValue: key,
            name: data[key].name,
            description: data[key].description,
            id: data[key].id
          }));
          setCountries(countryNames);
          setFetchedCountries(countryNames);
        } else {
          logger.error("Failed to fetch countries");
        }
      } catch (error) {
        logger.error("Error fetching countries:", error);
      }
    };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

const handleEdit = (id) => {
  const holidayToEdit = holidays.find(holiday => holiday.id === id);
  const { startDate, endDate, reason, countries } = holidayToEdit;
  const countryEnumMap = fetchedCountries.reduce((map, country) => {
    map[country.id] = country.enumValue;
    return map;
  }, {});
  const countryEnumValues = countries.map(country => countryEnumMap[country.id]);
  navigate('/addholiday', { state: { holidayToEdit: { id, startDate, endDate, reason, countries: countryEnumValues } } });
};
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(holidays.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const getCountryNames = (countryEnums) => {
     return countryEnums.map(enumValue => {
       const country = countries.find(c => c.enumValue === enumValue);
       return country ? country.name : enumValue;
     }).join(', ');
  };

  const sortedHolidayData = [...holidays].sort((a, b) => {
    const dateCompareFirst = new Date(a.startDate);
    const dateCompareSecond = new Date(b.startDate);
    return dateCompareFirst - dateCompareSecond;
  });

  return (
    <div className="container-fluid">
      <div className="holiday-list-container">
        <table className="holiday-table">
          <thead>
            <tr>
              <th>Occasion</th>
              <th>Start Date (dd/mm/yyyy)</th>
              <th>End Date (dd/mm/yyyy)</th>
              {(userRole === 1 || userRole === 2)&& <th>Countries</th>}
              {(userRole === 1 || userRole === 2 )&& <th>Edit</th>}
            </tr>
          </thead>
          <tbody>
             {sortedHolidayData.slice(offset, offset + itemsPerPage).map((holiday) => (
                <tr key={holiday.id}>
                <td>{holiday.reason}</td>
                <td>{formatDate(holiday.startDate)}</td>
                <td>{formatDate(holiday.endDate)}</td>
                {(userRole === 1 || userRole === 2) && <td>{holiday.countryNames.join(', ')}</td>}
                {(userRole === 1 || userRole === 2)  && (
                <td>
                    <button className="allusers-edit-button" onClick={() => handleEdit(holiday.id)}>Edit</button>
                </td>
                )}
                </tr>
            ))}
          </tbody>
        </table>
      </div>
        <ReactPaginate
            previousLabel={ <>
              <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
              <span className="icon-pagination">Previous</span>
            </>}
            nextLabel={ <>
             <div className="next-container">
               <span className="icon-pagination">Next</span>
               <FontAwesomeIcon icon={faArrowRight} className="icon-right" />
             </div>
            </>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination-req'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            previousClassName={currentPage === 0 ? "disabled-button" : ""}
            nextClassName={currentPage === pageCount - 1 ? "disabled-button" : ""}
        />
   </div>
  );
};

export default HolidayDetails;
