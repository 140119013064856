import React,{useState, useEffect}  from "react";
import { Link } from 'react-router-dom';
import { UserHeaders } from './constant/localStorageConstants';
import logger from './logs/logger';
import { API_BASE_URL } from './constant/apiConstants';
import { getAuthHeaders } from './authUtils';
import { useNavigate } from 'react-router-dom';
import { faUser, faHome, faPlus, faFileAlt, faDownload, faUpload, faUserSlash, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Hamburger from 'hamburger-react';
import "./styles/ProfilePage.css";
import { routeConfig } from './config';

const Navigation = ({ activeNavItem, handleNavItemClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth >= 1200);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 1200) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleItemClick = (index) => {
    handleNavItemClick(index);
    closeNavigation(); // Close navigation menu
  };

  const toggleNavigation = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOpenImageClick = () => {
    navigate('/home');
  };

  const closeNavigation = () => {
    setIsMenuOpen(false);
  }

  return (
    <nav className={`main-menu ${isMenuOpen ? "open" : ""}`}>
      {isMenuOpen && <div className="open-image-container" onClick={handleOpenImageClick}> </div>}
      {!isMenuOpen && <div className="close-image-container" onClick={handleOpenImageClick}> </div>}
      <div className="toggle-icon" onClick={toggleNavigation}>
        {isMenuOpen ? (
          <FontAwesomeIcon icon={faArrowLeft} className="arrow-nav-icon" />
        ) : (
          <FontAwesomeIcon icon={faArrowRight} className="right-arrow-nav-icon" />
        )}
      </div>

      <div className="sidebar-content">
        <ul className="nav-ul">
          <li className={`nav-item ${activeNavItem === 0 ? "active" : ""}`} onClick={() => handleNavItemClick(0)} >
            <Link to="/home">
              <div className={`home-image-container ${activeNavItem === 0 ? "active" : ""}`}></div>
              <span className="nav-text">Home</span>
            </Link>
          </li>

          {(UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value === 2 || UserHeaders.USERROLE.value === 6) && (
            <>
              <li className={`nav-item ${activeNavItem === 2 ? "active" : ""}`} onClick={() => handleNavItemClick(2)} >
                <Link to="/allusers">
                  <div className={`activeEmployee-image-container ${activeNavItem === 2 ? "active" : ""}`}></div>
                  <span className="nav-text">Active Employees</span>
                </Link>
              </li>

              <li className={`nav-item ${activeNavItem === 3 ? "active" : ""}`} onClick={() => handleNavItemClick(3)} >
                <Link to="/addemployee">
                  <div className={`addEmployee-image-container ${activeNavItem === 3 ? "active" : ""}`}></div>
                  <span className="nav-text">Add Employee</span>
                </Link>
              </li>
              <li className={`nav-item ${activeNavItem === 4 ? "active" : ""}`} onClick={() => handleNavItemClick(4)} >
                <Link to="/addholiday">
                  <div className={`addHoliday-image-container ${activeNavItem === 4 ? "active" : ""}`}></div>
                  <span className="nav-text">Add Holidays</span>
                </Link>
              </li>
            </>
          )}
          {routeConfig.showUploadSalarySlip && UserHeaders.DEPARTMENTID.value === 3 && (
            <>
              <li className={`nav-item ${activeNavItem === 5 ? "active" : ""}`} onClick={() => handleNavItemClick(5)} >
                <Link to="/uploadsalaryslip">
                  <div className={`uploadsalaryslip-image-container ${activeNavItem === 5 ? "active" : ""}`}></div>
                  <span className="nav-text">Upload Payslip</span>
                </Link>
              </li>
            </>
          )}

          {routeConfig.showDownloadSalarySlip && (
            <li className={`nav-item ${activeNavItem === 6 ? "active" : ""}`} onClick={() => handleNavItemClick(6)} >
              <Link to="/downloadsalaryslip">
                <div className={`downloadsalaryslip-image-container ${activeNavItem === 6 ? "active" : ""}`}></div>
                <span className="nav-text">Download Payslip</span>
              </Link>
            </li>
          )}

          {routeConfig.showApproveSalarySlip && UserHeaders.USERROLE.value === 3 && (
            <>
              <li className={`nav-item ${activeNavItem === 9 ? "active" : ""}`} onClick={() => handleNavItemClick(6)} >
                <Link to="/approvesalaryslip">
                  <FontAwesomeIcon icon={faCheckSquare} className="nav-icon" />
                  <span className="nav-text">Approval Payslip</span>
                </Link>
              </li>
            </>
          )}

          {(UserHeaders.EMPLOYEESTATUS.value === 2 || (UserHeaders.EMPLOYEESTATUS.value === 1 && (UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value === 2))) && (
            <>
              <li className={`nav-item ${activeNavItem === 7 ? "active" : ""}`} onClick={() => handleNavItemClick(7)} >
                {UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value === 2 ? (
                  <>
                    <Link to="/reports">
                      <div className={`leavereports-image-container ${activeNavItem === 7 ? "active" : ""}`}></div>
                      <span className="nav-text">Leave Reports</span>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/userreports">
                      <div className={`leavereports-image-container ${activeNavItem === 7 ? "active" : ""}`}></div>
                      <span className="nav-text">Leave Reports</span>
                    </Link>
                  </>
                )}
              </li>
            </>
          )}

          {(UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value === 2 || UserHeaders.USERROLE.value === 6) && (
            <li className={`nav-item ${activeNavItem === 8 ? "active" : ""}`} onClick={() => handleNavItemClick(8)} >
              <Link to="/exemployees">
                <div className={`exemployees-image-container ${activeNavItem === 8 ? "active" : ""}`}></div>
                <span className="nav-text">Ex-Employees</span>
              </Link>
            </li>
          )}

          {(UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value === 2) && (
            <li className={`nav-item ${activeNavItem === 9 ? "active" : ""}`} onClick={() => handleNavItemClick(9)} >
              <Link to="/empapproval">
                <div className={`empapproval-image-container ${activeNavItem === 9 ? "active" : ""}`}></div>
                <span className="nav-text">Employee Approval</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;
